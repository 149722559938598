export function getRequestHeaders(
  withAuth = true,
  formData = false
): Record<string, string> {
  const { branch, terminalId } = useInitialLocalStore();
  const token = useCookie("auth.token");

  const headers: Record<string, string> = {
    Accept: formData ? "multipart/form-data" : "application/json",
    "Accept-Language": "th",
    "X-Branch-ID": (branch.value?.id as string) || "",
    "X-Terminal-ID": (terminalId?.value as string) || "",
  };

  if (withAuth) {
    headers.Authorization = `Bearer ${token.value}`;
  }
  return headers;
}
