import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import th from "dayjs/locale/th";

export default defineNuxtPlugin(() => {
  const Dayjs = dayjs;
  Dayjs.extend(buddhistEra);
  Dayjs.locale(th);

  return {
    provide: {
      dayjs: Dayjs,
    },
  };
});
