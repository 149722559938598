<template>
  <div>
    <Head>
      <!-- <Title>POS System</Title> -->
      <Body
        class="custom-scroll-bigger bg-white dark:bg-gray-800"
        :class="{ '!overflow-hidden': isLocked }"
      />
    </Head>
    <NuxtLayout>
      <NuxtLoadingIndicator></NuxtLoadingIndicator>
      <NuxtPage />
    </NuxtLayout>
    <!-- Popup -->
    <AppToastList />
    <AppConfirmList />
    <ClientOnly>
      <app-loading />
    </ClientOnly>
  </div>
</template>

<script setup>
import AppLoading from "~/components/elements/utils/AppBlockLoading.vue";
import AppToastList from "~/components/elements/utils/AppToastList.vue";
import AppConfirmList from "~/components/elements/utils/AppConfirmList.vue";

const { isLocked } = useLockScroll();

useHead({
  titleTemplate: (titleChunk) => {
    const title = titleChunk ? `${titleChunk} - Terra` : `Terra`;
    return process.env.NODE_ENV === "development" ? `${title} (👷Dev)` : title;
  },
});
</script>
