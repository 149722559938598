interface LockScroll_t {
  isLocked: Ref<boolean>;
}

const isLocked: Ref<boolean> = ref(false);
export const useLockScroll = (): LockScroll_t => {
  return {
    isLocked,
  };
};
