interface FormatNumber_t {
  toReadable: () => string;
  toNumber: () => number;
}

export function checkAuthError(statusCode: number) {
  if (statusCode === 401) {
    const cookies = useCookie("auth.token");
    cookies.value = null;
    navigateTo("/");
  }
}

export function abbreviateNumber(num: number): string {
  if (num <= 0) return "0";
  const abbreviations = ["", "K", "M", "B", "T"];
  // Find the appropriate abbreviation based on the magnitude of the number
  const magnitude = Math.floor(Math.log10(num) / 3);
  const abbreviatedNum = num / Math.pow(1000, magnitude);
  // Convert the number to a string with a maximum of 2 decimal places
  const roundedNum = parseFloat(abbreviatedNum.toFixed(2));
  return `${roundedNum}${abbreviations[magnitude] || ""}`;
}

// Number Formatter
// Number Formatter
// Number Formatter
export function FormatNumber(number: string | number): FormatNumber_t {
  const { $numeral: Numeral } = useNuxtApp();
  const numeralObject = Numeral(number);

  function toReadable(symbol = true): string {
    const output = numeralObject.format("0,0");
    return symbol ? `${output} ฿` : output;
  }

  function toNumber(): number {
    return numeralObject.value();
  }

  return {
    toReadable,
    toNumber,
  };
}
export function SimpleNumberFormat(value: string | number) {
  return Intl.NumberFormat("th-TH").format(Number(value));
}

// Key Converter
// Key Converter
// Key Converter

type ConvertOptions = "camelToSnake" | "snakeToCamel";
/* eslint-disable */
export function convertKeys(obj: any, convertOption: ConvertOptions): any {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeys(item, convertOption));
  }
  const convertedObj: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      let newKey: string;
      if (convertOption === "snakeToCamel") {
        newKey = key.replace(/_([a-z])/g, (_, c) => c.toUpperCase());
      } else if (convertOption === "camelToSnake") {
        newKey = key.replace(/([A-Z])/g, (_, c) => `_${c.toLowerCase()}`);
      } else {
        newKey = key; // Default to keep the key unchanged
      }
      convertedObj[newKey] = convertKeys(obj[key], convertOption);
    }
  }
  return convertedObj;
}
/* eslint-enable */

// Profile Placeholder
// Profile Placeholder
// Profile Placeholder
function filterThaiAndEnglishConsonants(value: string) {
  // Regular expression to match Thai and English consonant characters
  const thaiAndEnglishConsonantRegex =
    /[\u0E01-\u0E1A\u0E1E-\u0E2E\u0041-\u005A\u0061-\u007A]+/g;

  // Use the match method with the regex to filter Thai and English consonant characters
  const thaiAndEnglishConsonantsArray = value.match(
    thaiAndEnglishConsonantRegex
  );

  // Join the filtered Thai and English consonant characters to form the resulting string
  const thaiAndEnglishConsonantsOnly = thaiAndEnglishConsonantsArray
    ? thaiAndEnglishConsonantsArray.join("")
    : "";

  return thaiAndEnglishConsonantsOnly;
}
export function profilePlaceholder(value: string) {
  if (!value) return "";
  const splitWords = value.split(" ");

  if (splitWords.length === 1) {
    // return 2 first char
    return filterThaiAndEnglishConsonants(splitWords[0]).slice(0, 2);
  } else {
    // return 1 first char of each word
    return splitWords
      .map((word) => filterThaiAndEnglishConsonants(word)[0])
      .join("");
  }
}

export function monthToRange(month: number) {
  const year = new Date().getFullYear();
  const _month = (month % 12) + 1;
  const start = `${year}-${_month.toString().padStart(2, "0")}-01`;
  const end = `${year}-${_month.toString().padStart(2, "0")}-${new Date(
    year,
    _month,
    0
  ).getDate()}`;
  return { start, end };
}

export function parseErrorMessage(error: any) {
  return error?.data?.message || error?.message || error || "ไม่ทราบสาเหตุ";
}
