import { gsap } from "gsap";
// import {ScrollTrigger} from 'gsap/ScrollTrigger'
// import {ScrollToPlugin} from 'gsap/ScrollToPlugin'

export default defineNuxtPlugin((nuxtApp) => {
  // if (process.client) {
  //    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)
  // }

  return {
    provide: {
      gsap,
      // CSSRulePlugin,
      // ScrollTrigger,
    },
  };
});
