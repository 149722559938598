export default defineNuxtRouteMiddleware((to) => {
  const token = useCookie("auth.token");
  const isLogged = Boolean(token.value);
  const ignorePath: string[] = [
    "index",
    "terminal-access-id",
    "pin-login",
    "forgot-password",
  ];
  if (!isLogged && !ignorePath.includes(to?.name?.toString() || "")) {
    return navigateTo("/");
  }
});
