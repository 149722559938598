export default defineNuxtRouteMiddleware((to) => {
  if (process.client && to.path.includes("/management")) {
    const initStore = useInitialLocalStore();
    let matchPaths: {
      path: string;
      passed: boolean;
    }[] = [];
    Object.entries(GENERAL_PERMISSION).forEach(([key, value]) => {
      if (to.path.includes(key)) {
        Object.entries(value).forEach(([subKey, subValue]) => {
          if (to.path.includes(subKey)) {
            matchPaths.push({
              path: subKey,
              passed: !!subValue(initStore),
            });
          }
        });
      }
    });

    // Trust permission of most specific path, use / as specific score
    matchPaths = matchPaths.sort((a, b) => {
      return b.path.split("/").length - a.path.split("/").length;
    });

    if (matchPaths.length === 0) return;
    if (matchPaths && !matchPaths[0].passed) {
      const { $toast } = useNuxtApp();
      $toast({
        type: "warning",
        title: "แจ้งเตือน",
        detail: `คุณไม่มีสิทธิในการเข้าถึงลิงค์นี้ ${to.path}`,
      });
      return navigateTo("/management");
    }
  }
});

type Permission_t = Record<
  string,
  (
    permits: ReturnType<typeof useInitialLocalStore>
  ) => boolean | undefined | null
>;

const GENERAL_PERMISSION: {
  [key: string]: Permission_t;
} = {
  "/admin": {
    "/admin": (permits) =>
      permits.managementPermission.value?.admin?.list.viewAny,
  },
  "/branch": {
    "/branch/group": (permits) =>
      permits.managementPermission.value?.branch?.group.viewAny,
    "/branch/kitchen": (permits) =>
      permits.managementPermission.value?.branch?.kitchen.viewAny,
    "/branch/payment": (permits) =>
      permits.managementPermission.value?.branch?.["payment-method"].viewAny,
    "/branch/printer": (permits) =>
      permits.managementPermission.value?.branch?.printer.viewAny,
    "/branch/printer/agent": (permits) =>
      permits.managementPermission.value?.branch?.["printer-agent"].viewAny,
    "/branch": (permits) =>
      permits.managementPermission.value?.branch?.list.viewAny,
  },
  "/keep/beverage": {
    "/keep/beverage/transfer/receive": (permits) =>
      permits.managementPermission.value?.beverage?.transfer.receive,
    "/keep/beverage/transfer/send": (permits) =>
      permits.managementPermission.value?.beverage?.transfer["send-out"],
    "/keep/beverage/transfer": (permits) =>
      permits.managementPermission.value?.beverage?.transfer.list,
    "/keep/beverage/brand": (permits) =>
      permits.managementPermission.value?.beverage?.brand.viewAny,
    "/keep/beverage/generate": (permits) =>
      permits.managementPermission.value?.beverage?.["bulk-generate"].generate,
    "/keep/beverage": (permits) =>
      permits.managementPermission.value?.beverage?.list.view,
  },
  "/keep/valet": {
    "/keep/valet/brand": (permits) =>
      permits.managementPermission.value?.valet?.["car-brand"].viewAny,
    "/keep/valet/place": (permits) =>
      permits.managementPermission.value?.valet?.["parking-place"].viewAny,
    "/keep/valet/zone": (permits) =>
      permits.managementPermission.value?.valet?.["parking-zone"].viewAny,
    "/keep/valet/scan": (permits) =>
      permits.managementPermission.value?.valet?.list.view,
    "/keep/valet/generate": (permits) =>
      permits.managementPermission.value?.valet?.create,
    "/keep/valet": (permits) =>
      permits.managementPermission.value?.valet?.list.viewAny,
  },
  "/material": {
    "/material/group": (permits) =>
      permits.managementPermission.value?.material?.group.viewAny,
    "/material/stock": (permits) =>
      permits.managementPermission.value?.material?.stock.view,
    "/material": (permits) =>
      permits.managementPermission.value?.material?.list.viewAny,
  },
  "/menu": {
    "/menu/topping/group": (permits) =>
      permits.managementPermission.value?.menu?.["topping-group"].viewAny,
    "/menu/topping": (permits) =>
      permits.managementPermission.value?.menu?.topping.viewAny,
    "/menu/promotion/group": (permits) =>
      permits.managementPermission.value?.menu?.["promotion-group"].viewAny,
    "/menu/promotion": (permits) =>
      permits.managementPermission.value?.menu?.promotion.viewAny,
    "/menu/group/new": (permits) =>
      permits.managementPermission.value?.menu?.group.create,
    "/menu/type": (permits) =>
      permits.managementPermission.value?.menu?.type.viewAny,
    "/menu/group": (permits) =>
      permits.managementPermission.value?.menu?.group.viewAny,
    "/menu": (permits) =>
      permits.managementPermission.value?.menu?.list.viewAny,
  },
  "/table": {
    "/table/zone": (permits) =>
      permits.managementPermission.value?.table?.zone.viewAny,
    "/table/generate": (permits) =>
      permits.managementPermission.value?.table?.list["bulk-create"],
    "/table": (permits) =>
      permits.managementPermission.value?.table?.list.viewAny,
  },
  "/terminal": {
    "/terminal": (permits) =>
      permits.managementPermission.value?.branch?.terminal.viewAny,
  },
  "/user": {
    "/user": (permits) =>
      permits.managementPermission.value?.user?.list.viewAny,
  },
  report: {
    "report/shift": (permits) =>
      permits.managementPermission.value?.shift?.list.viewAny,
    "report/product": (permits) =>
      permits.managementPermission.value?.report?.view.sale,
    "report/employee": (permits) =>
      permits.managementPermission.value?.report?.view["employee-sale"],
    "report/activity": (permits) =>
      permits.managementPermission.value?.report?.view.activity,
    "report/summary": (permits) =>
      permits.managementPermission.value?.report?.view.summary,
    "report/stock": (permits) =>
      permits.managementPermission.value?.report?.view.stock,
    "report/payment": (permits) =>
      permits.managementPermission.value?.report?.view["payment-method"],
  },
  executive: {
    "/executive": (permits) =>
      permits.managementPermission.value?.executive?.dashboard.view,
    "/executive/live": (permits) =>
      permits.managementPermission.value?.executive?.dashboard.view,
    "/executive/report": (permits) =>
      permits.managementPermission.value?.executive?.dashboard.view,
  },
};
