import { useConfirmation } from "~/store/confirmation";
import { useLoading } from "~/store/loading";
import { useToast } from "~/store/toast";

export default defineNuxtPlugin(() => {
  const { add: _toast } = useToast();
  const { add: _confirm } = useConfirmation();
  const { loading: _loading, done: _done } = useLoading();

  return {
    provide: {
      toast: useToast().add,
      confirm: useConfirmation().add,
      loading: useLoading(),
      proceed(
        name: string,
        logic: () => Promise<any>,
        onComplete?: (res: any) => void | Promise<void>,
        onError?: (error: any) => void | Promise<void>
      ) {
        // this is combination of toast loading and confirmation
        _confirm({
          title: `ยืนยันการดำเนินการ`,
          detail: `คุณแน่ใจหรือไม่ว่าต้องการ${name}?`,
          async confirm() {
            _loading();
            try {
              const res = await logic();
              _toast({
                title: "สำเร็จ",
                detail: `คุณได้ ${name} สำเร็จแล้ว`,
                type: "success",
              });
              if (onComplete) onComplete(res);
            } catch (error) {
              // for token expired
              if ((error as any).response?.status) {
                checkAuthError((error as any).response.status);
              }

              _toast({
                title: "เกิดข้อผิดพลาด",
                detail:
                  (error as any).data?.message ||
                  (error as any).message ||
                  `คุณไม่สามารถ${name}ได้`,
                type: "error",
              });
              if (onError) onError(error);
            } finally {
              _done();
            }
          },
        });
      },
      async proceedNoConfirm(
        name: string,
        logic: () => Promise<void>,
        onComplete?: () => any,
        onError?: (error: any) => void
      ) {
        _loading();
        try {
          await logic();
          _toast({
            title: "สำเร็จ",
            detail: `คุณได้ ${name} สำเร็จแล้ว`,
            type: "success",
          });
          if (onComplete) await onComplete();
        } catch (error) {
          // for token expired
          if ((error as any).response?.status) {
            checkAuthError((error as any).response.status);
          }

          _toast({
            title: "เกิดข้อผิดพลาด",
            detail: (error as any).data?.message || `คุณไม่สามารถ${name}ได้`,
            type: "error",
          });
          if (onError) onError(error);
        } finally {
          _done();
        }
      },
    },
  };
});
