interface Confirm_t {
  title: string;
  detail: string;
  buttonText?: {
    confirm: string;
    cancel: string;
  };
  hideClose?: boolean;
  confirm: () => Promise<any> | any;
}

interface ConfirmContainer_t {
  id: string;
  confirm: Confirm_t;
}

export const useConfirmation = defineStore("confirmation-service", () => {
  const list = ref<ConfirmContainer_t[]>([]);
  // handlers
  function add(confirm: Confirm_t) {
    const adding = {
      id: Math.random().toString(36).substring(2, 9),
      confirm,
    };
    list.value.push(adding);
    nextTick(() => {
      const el = window.document.getElementById(
        `confirm-button-${adding.id}`
      ) as HTMLButtonElement;
      el.focus();
    });
  }
  async function onConfirmById(id: string) {
    const item = list.value.find((item) => item.id === id);
    if (item) {
      await item.confirm.confirm();
      onRemoveById(id);
    }
  }
  function onRemoveById(id: string) {
    list.value.splice(
      list.value.findIndex((item) => item.id === id),
      1
    );
  }
  function $reset() {
    list.value = [];
  }

  watch(
    () => list.value.length,
    (len) => {
      if (len > 0) {
        // disabled scroll
        document.body.style.overflow = "hidden";
      } else {
        // enable scroll
        document.body.style.overflow = "auto";
      }
    }
  );

  return {
    list,
    add,
    onRemoveById,
    $reset,
    onConfirmById,
  };
});
