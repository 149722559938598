import type { DrawerOptions } from "flowbite";
import { Drawer } from "flowbite";
import type { Ref } from "vue";

const drawer: Ref<any> = ref();

interface DrawerSale_t {
  createDrawerSale: () => void;
  drawer: Ref<any>;
}

export const useDrawerSale = (): DrawerSale_t => {
  const createDrawerSale = (): void => {
    const targetEl: HTMLElement | null = document.getElementById("drawer-sale");
    const targetBackdrop: HTMLElement | null = document.getElementById(
      "drawer-sale-backdrop"
    );
    const options: DrawerOptions = {
      placement: "right",
      backdrop: false,
      bodyScrolling: false,
      edge: false,
      onHide: (): void => {
        targetBackdrop?.classList.toggle("invisible");
        targetBackdrop?.classList.toggle("opacity-0");
      },
      onShow: (): void => {
        targetBackdrop?.classList.toggle("invisible");
        targetBackdrop?.classList.toggle("opacity-0");
      },
    };
    drawer.value = new Drawer(targetEl, options);
  };

  return {
    drawer,
    createDrawerSale,
  };
};
