import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const {
    public: { sentry },
  }: any = useRuntimeConfig();
  const {
    public: { apiHost },
  }: any = useRuntimeConfig();

  if (!sentry.dsn) {
    return;
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [apiHost],
      }),
    ],
    tracesSampleRate: 0.05,
    tracePropagationTargets: ["localhost", apiHost],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.1,
  });
});
