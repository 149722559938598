<template>
  <div
    v-if="active"
    class="fixed left-0 top-0 w-screen h-screen bg-black bg-opacity-60 backdrop-blur-md z-[999] flex items-center justify-center"
  >
    <div class="loader [ scale-150 ]"></div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useLoading } from "~/store/loading";

const { active } = storeToRefs(useLoading());
</script>

<style scoped>
.loader {
  width: 48px;
  height: 48px;
  display: block;
  margin: 15px auto;
  position: relative;
  color: #fff;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  left: 50%;
  transform: scale(0.5) translate(0, 0);
  background-color: #fff;
  border-radius: 50%;
  animation: animloader 1s infinite ease-in-out;
}
.loader::before {
  background-color: #eab306;
  transform: scale(0.5) translate(-48px, -48px);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animloader {
  50% {
    transform: scale(1) translate(-50%, -50%);
  }
}

/* .loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  background: rgb(234, 179, 8);
  width: 16px;
  height: 16px;
  transform: translate(-50%, 50%);
  border-radius: 50%;
}
.loader::before {
  left: auto;
  right: 0;
  background: #fff;
  transform: translate(50%, 100%);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */
</style>
