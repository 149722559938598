<template>
  <TransitionGroup
    v-for="align in ['top-right', 'top-left', 'bottom-right', 'bottom-left']"
    :key="align"
    tag="ul"
    name="list"
    class="fixed group/ul z-[1100] max-h-[min(25,40vh)]"
    :class="{
      'top-5 right-5 mt-[40px]': align === 'top-right',
      'top-5 left-5 mt-[40px]': align === 'top-left',
      'bottom-5 right-5': align === 'bottom-right',
      'bottom-5 left-5': align === 'bottom-left',
    }"
    @mouseenter="stopAllTimer"
    @mouseleave="restartAllTimer"
  >
    <li
      v-for="{ id, toast } in list.filter(
        (toastContainer) => toastContainer.toast.align === align
      )"
      :key="`${align}-${id}`"
      class="relative | w-[min(30rem,90vw)] group md:group-hover/ul:ml-[1rem] md:hover:mr-[1rem] md:hover:!ml-[0] transition-all duration-300"
    >
      <div
        class="group-hover/ul:bg-opacity-40 group-hover/ul:saturate-0 hover:!saturate-100 !backdrop-blur-md group-hover:!bg-opacity-100 flex items-center w-full p-4 mb-4 text-gray-500 bg-white rounded-lg shadow-sm dark:text-gray-400 dark:bg-gray-800 transition-all duration-300"
        :class="[
          toast.type === 'success'
            ? 'border border-l-4 border-green-500 group-hover:dark:shadow-green-500 group-hover:shadow-lg'
            : '',
          toast.type === 'warning'
            ? 'border border-l-4 border-yellow-500 group-hover:dark:shadow-yellow-500 group-hover:shadow-lg'
            : '',
          toast.type === 'error'
            ? 'border border-l-4 border-red-500 group-hover:dark:shadow-red-500 group-hover:shadow-lg'
            : '',
          toast.type === 'confirm'
            ? 'border border-l-4 border-blue-500 group-hover:dark:shadow-blue-500 group-hover:shadow-lg'
            : '',
        ]"
        role="alert"
      >
        <div
          class="self-start inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg [ animate__animated animate__heartBeat ]"
          :class="[
            toast.type === 'success'
              ? 'text-green-500 bg-green-100 dark:bg-green-800 dark:text-green-200'
              : '',
            toast.type === 'warning'
              ? 'text-yellow-500 bg-yellow-100 dark:bg-yellow-800 dark:text-yellow-200'
              : '',
            toast.type === 'error'
              ? 'text-red-500 bg-red-100 dark:bg-red-800 dark:text-red-200'
              : '',
            toast.type === 'confirm'
              ? 'text-blue-500 bg-blue-100 dark:bg-blue-800 dark:text-blue-200'
              : '',
          ]"
        >
          <font-awesome-icon
            v-if="toast.type === 'success'"
            class="w-4"
            :icon="['fas', 'check']"
          ></font-awesome-icon>
          <font-awesome-icon
            v-else-if="toast.type === 'warning'"
            class="w-4"
            :icon="['fas', 'brake-warning']"
          ></font-awesome-icon>
          <font-awesome-icon
            v-else-if="toast.type === 'confirm'"
            class="w-4"
            :icon="['fas', 'circle-question']"
          ></font-awesome-icon>
          <font-awesome-icon
            v-else
            class="w-4"
            :icon="['fas', 'bug']"
          ></font-awesome-icon>
        </div>
        <div
          class="w-full | ml-3 font-normal max-h-[5rem] overflow-y-hidden group-hover:max-h-[90vh] md:group-hover:max-h-[80vh] transition-all duration-300 custom-scroll"
        >
          <h1
            class="font-bold [ animate__animated animate__fadeInDown animate__faster ] text-lg"
            style="animation-delay: 0.1s"
            :class="[
              toast.type === 'success' ? 'text-green-500' : '',
              toast.type === 'warning' ? 'text-yellow-500' : '',
              toast.type === 'error' ? 'text-red-500' : '',
              toast.type === 'confirm' ? 'text-blue-500' : '',
            ]"
          >
            {{ toast.title }}
          </h1>
          <p
            class="[ animate__animated animate__fadeIn animate__faster ] text-base"
            style="animation-delay: 0.2s"
          >
            {{ toast.detail }}
          </p>
          <div
            v-if="toast.type === 'confirm'"
            class="flex w-fit gap-4 ml-auto group-hover:pb-2 group-hover:max-h-[3rem] group-hover:mt-[1rem] | max-h-0 pb-0 overflow-hidden transition-all duration-300"
          >
            <Button
              v-if="toast.onConfirm"
              size="sm"
              @click.prevent="
                async () => {
                  if (toast.onConfirm) {
                    await handleRunner(id, toast.onConfirm);
                  }
                }
              "
            >
              {{ toast?.confirmText || "ยืนยัน" }}
            </Button>
            <Button
              size="sm"
              outline
              @click.prevent="
                async () => {
                  if (toast.onCancel) {
                    await toast.onCancel();
                  }
                  removeById(id);
                }
              "
            >
              {{ toast?.cancelText || "ยกเลิก" }}
            </Button>
          </div>
        </div>

        <button
          v-if="toast.type !== 'confirm'"
          type="button"
          class="self-start ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
          @click="removeById(id)"
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    </li>
  </TransitionGroup>
</template>

<script setup lang="ts">
import { Button } from "flowbite-vue";
import { useToast } from "~/store/toast";

const { list, removeById, stopAllTimer, restartAllTimer } = useToast();

async function handleRunner(id: string, callback: () => Promise<void>) {
  await callback();
  removeById(id);
}
</script>

<style scoped>
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease-in-out;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}
</style>
