<template>
  <div>
    <div
      v-for="{ id, confirm } in list"
      :key="id"
      class="fixed inset-0 z-[101] bg-black bg-opacity-60 backdrop-blur-md text-black flex items-center justify-center"
    >
      <!--      @click.self="onRemoveById(id)"-->
      <form
        class="bg-gray-50 dark:bg-gray-800 text-black dark:text-white p-3 px-5 rounded-lg sm:py-5 min-w-[min(20rem,90vw)] max-w-[min(30rem,90vw)] flex flex-col gap-5 shadow-xl focus:border focus:border-red-500"
        @submit.prevent="onConfirmById(id)"
      >
        <div for="question" class="flex flex-col gap-2">
          <h1 class="text-lg">{{ confirm.title }}</h1>
          <p class="opacity-50">{{ confirm.detail }}</p>
        </div>
        <div for="choices" class="flex gap-2 justify-between">
          <ElementsUtilsAppButton
            :class="{ invisible: confirm?.hideClose }"
            color="gray"
            outline
            :label="confirm.buttonText?.cancel || 'ยกเลิก'"
            :icon="['fas', 'ban']"
            @click.prevent="onRemoveById(id)"
          ></ElementsUtilsAppButton>
          <ElementsUtilsAppButton
            :id="`confirm-button-${id}`"
            color="yellow"
            type="submit"
            :label="confirm.buttonText?.confirm || 'ยืนยัน'"
            :icon="['fas', 'check']"
            icon-position="after"
          ></ElementsUtilsAppButton>
          <!-- <button
            :id="`confirm-button-${id}`"
            type="submit"
            class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          >
            {{ confirm.buttonText?.confirm || "ยืนยัน" }}
          </button> -->
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useConfirmation } from "~/store/confirmation";

const { list, onRemoveById, onConfirmById } = useConfirmation();

watch(
  () => useRoute().fullPath,
  () => {
    list.pop();
  }
);
</script>
