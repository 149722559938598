import type { Ref } from "vue";
import { getRequestHeaders } from "~/utils/api";
import type {
  PaymentMethodItem_t,
  SubmitPaymentPayload_t,
} from "assets/types/payment";
import { checkAuthError } from "~/utils/helpers";
import type { InformationTableFormType_t } from "assets/types/tables";

interface UsePayment_t {
  getPaymentMethods: (
    id: string | null,
    billActive: string | number,
    type: InformationTableFormType_t
  ) => Promise<unknown>;

  printPreview: (
    id: string | null,
    billActive: string | number,
    type: InformationTableFormType_t,
    paymentMethod?: string
  ) => Promise<unknown>;

  requestGbPrimeQr: (
    id: string | null,
    billActive: string | number,
    type: InformationTableFormType_t,
    paymentMethod?: string
  ) => Promise<unknown>;

  submitPayment: (
    id: string | null,
    payload: SubmitPaymentPayload_t,
    type: InformationTableFormType_t
  ) => Promise<unknown>;

  confirmPayment: (
    id: string | null,
    type: InformationTableFormType_t
  ) => Promise<unknown>;

  checkGBPrimeStatus: (
    id: string | null,
    billActive: string | number,
    paymentMethod: string,
    type: InformationTableFormType_t
  ) => Promise<unknown>;
  paymentMethodsLists: Ref<PaymentMethodItem_t[]>;
}

const paymentMethodsLists: Ref<PaymentMethodItem_t[]> = ref([]);
export const usePayment = (): UsePayment_t => {
  const getPaymentMethods = (
    id: string | null,
    billActive: string | number,
    type: InformationTableFormType_t
  ) => {
    const createParams: { bill_no?: string | number } = {};
    if (billActive !== "all") createParams.bill_no = billActive;
    const apiPath =
      type === "dineIn"
        ? `/pos-api/table/${id}/payment-method`
        : `/pos-api/takeaway/order/${id}/payment-method`;
    return $fetch(apiPath, {
      method: "GET",
      params: createParams,
      headers: getRequestHeaders(),
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };
  const printPreview = (
    id: string | null,
    billActive: string | number,
    type: InformationTableFormType_t,
    paymentMethod?: string
  ) => {
    const createPayload: {
      bill_no?: string | number;
      payment_method_id?: string | number;
    } = {};
    if (billActive !== "all") createPayload.bill_no = billActive;
    if (paymentMethod) createPayload.payment_method_id = paymentMethod;

    const apiPath =
      type === "dineIn"
        ? `/pos-api/table/${id}/print-preview`
        : `/pos-api/takeaway/order/${id}/print-preview`;
    return $fetch(apiPath, {
      method: "POST",
      headers: getRequestHeaders(),
      body: createPayload,
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };
  const requestGbPrimeQr = (
    id: string | null,
    billActive: string | number,
    type: InformationTableFormType_t,
    paymentMethod?: string
  ) => {
    const createPayload: {
      bill_no?: string | number;
      payment_method_id?: string | number;
    } = {};
    if (billActive !== "all") createPayload.bill_no = billActive;
    if (paymentMethod) createPayload.payment_method_id = paymentMethod;

    const apiPath =
      type === "dineIn"
        ? `/pos-api/table/${id}/request-gbprime-qr`
        : `/pos-api/takeaway/order/${id}/request-gbprime-qr`;
    return $fetch(apiPath, {
      method: "POST",
      headers: getRequestHeaders(),
      body: createPayload,
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };

  const submitPayment = (
    id: string | null,
    payload: SubmitPaymentPayload_t,
    type: InformationTableFormType_t
  ) => {
    const apiPath =
      type === "dineIn"
        ? `/pos-api/table/${id}/submit-payment`
        : `/pos-api/takeaway/order/${id}/submit-payment`;
    return $fetch(apiPath, {
      method: "POST",
      headers: getRequestHeaders(),
      body: payload,
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };

  const confirmPayment = (
    id: string | null,
    type: InformationTableFormType_t
  ) => {
    const apiPath =
      type === "dineIn"
        ? `/pos-api/table/${id}/confirm-payment`
        : `/pos-api/takeaway/order/${id}/confirm-payment`;
    return $fetch(apiPath, {
      method: "POST",
      headers: getRequestHeaders(),
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };

  const checkGBPrimeStatus = (
    id: string | null,
    billActive: string | number,
    paymentMethod: string,
    type: InformationTableFormType_t
  ) => {
    const apiPath =
      type === "dineIn"
        ? `/pos-api/table/${id}/check-gbprime-status`
        : `/pos-api/takeaway/order/${id}/check-gbprime-status`;
    return $fetch(apiPath, {
      method: "POST",
      body: {
        bill_no: billActive,
        payment_method_id: paymentMethod,
      },
      headers: getRequestHeaders(),
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };

  return {
    getPaymentMethods,
    printPreview,
    requestGbPrimeQr,
    submitPayment,
    confirmPayment,
    checkGBPrimeStatus,
    paymentMethodsLists,
  };
};
