import type { RemovableRef } from "@vueuse/core";
import { useStorage } from "@vueuse/core";
import type {
  BranchUser_t,
  InitialLocalStore_t,
  UserProfile_t,
} from "@/assets/types/authenticationType";
import type {
  ManagementPermissions_t,
  PosPermissions_t,
} from "@/assets/types/permissionType";

const branch: RemovableRef<Partial<BranchUser_t> | null> = useStorage(
  "branch",
  null,
  undefined,
  {
    serializer: {
      read: (v: any) => (v ? JSON.parse(v) : null),
      write: (v): string => {
        return JSON.stringify(v);
      },
    },
  }
);

const profile: RemovableRef<Partial<UserProfile_t> | null> = useStorage(
  "profile",
  null,
  undefined,
  {
    serializer: {
      read: (v: any) => (v ? JSON.parse(v) : null),
      write: (v): string => {
        return JSON.stringify(v);
      },
    },
  }
);

const managementPermission: RemovableRef<Partial<ManagementPermissions_t> | null> =
  useStorage("permissions.management", null, undefined, {
    serializer: {
      read: (v: any) => (v ? JSON.parse(v) : null),
      write: (v): string => {
        return JSON.stringify(v);
      },
    },
  });

const posPermission: RemovableRef<Partial<PosPermissions_t> | null> =
  useStorage("permissions.pos", null, undefined, {
    serializer: {
      read: (v: any) => (v ? JSON.parse(v) : null),
      write: (v): string => {
        return JSON.stringify(v);
      },
    },
  });

const isOneManagementPermissionActive: RemovableRef<string | null> =
  useStorage<string>("isOnePermissionManagementActive", null);
const isOnePosPermissionActive: RemovableRef<string | null> = useStorage(
  "isOnePermissionPosActive",
  null
);

const terminalId: RemovableRef<string | null> = useStorage("terminalId", null);
const terminalName: RemovableRef<string | null> = useStorage(
  "terminalName",
  null
);

export const useInitialLocalStore = (): InitialLocalStore_t => {
  return {
    profile,
    branch,
    managementPermission,
    posPermission,
    isOneManagementPermissionActive,
    isOnePosPermissionActive,
    terminalId,
    terminalName,
  };
};
