import { getRequestHeaders } from "~/utils/api";
import type {
  MenuProductPayload_t,
  InformationTableFormType_t,
} from "@/assets/types/tables";
import { checkAuthError } from "~/utils/helpers";

type ModalAction_t =
  | "void"
  | "move"
  | "discount"
  | "split"
  | "additionalCost"
  | "editVat"
  | "editServiceCharge"
  | "moveBill"
  | "editPrice"
  | "discountOrder"
  | "remark";

type ModalActionTitle = {
  title: string;
  type: ModalAction_t;
};

interface UseTable_t {
  getZone: () => Promise<unknown>;
  getTables: (id: string, payload?: any) => Promise<unknown>;
  lockTables: (
    tableId: string | string[] | null,
    isLock: boolean
  ) => Promise<unknown>;
  lockBill: (
    tableId: string | string[] | null,
    isLock: boolean,
    billNo: string | number
  ) => Promise<unknown>;
  findTable: (
    tableId: string | null,
    billNo: string | number,
    type: InformationTableFormType_t
  ) => Promise<unknown>;
  addMenuToTable: (
    tableId: string,
    payload: MenuProductPayload_t,
    type: InformationTableFormType_t
  ) => Promise<unknown>;
  voidItem: (
    tableId: string,
    payload: any,
    type: InformationTableFormType_t
  ) => Promise<unknown>;
  moveItem: (tableId: string, payload: any) => Promise<unknown>;
  discountItem: (
    tableId: string,
    payload: any,
    type: InformationTableFormType_t
  ) => Promise<unknown>;
  splitOrderItem: (
    tableId: string,
    payload: any,
    type: InformationTableFormType_t
  ) => Promise<unknown>;
  splitBill: (
    tableId: string,
    payload: any,
    type: InformationTableFormType_t
  ) => Promise<unknown>;
  cancelSplitBill: (
    tableId: string,
    payload: any,
    type: InformationTableFormType_t
  ) => Promise<unknown>;
  addAdditionalCost: (tableId: string, payload: any) => Promise<unknown>;
  removeAdditionalCost: (
    tableId: string | null,
    payload: any
  ) => Promise<unknown>;
  getPaymentTransaction: (
    tableId: string | null,
    billNo: string | number | undefined
  ) => Promise<unknown>;
  addDiscountOrder: (tableId: string, payload: any) => Promise<unknown>;
  setVatStatus: (
    tableId: string,
    payload: any,
    type: InformationTableFormType_t
  ) => Promise<unknown>;
  setServiceChargeStatus: (
    tableId: string,
    payload: any,
    type: InformationTableFormType_t
  ) => Promise<unknown>;
  getMovableBill: (
    tableId: string,
    payload: any,
    type: InformationTableFormType_t
  ) => Promise<unknown>;
  moveBill: (
    tableId: string,
    payload: any,
    type: InformationTableFormType_t
  ) => Promise<unknown>;
  setItemPrice: (
    tableId: string,
    payload: any,
    type: InformationTableFormType_t
  ) => Promise<unknown>;
  setRemark: (
    tableId: string,
    payload: any,
    type: InformationTableFormType_t
  ) => Promise<unknown>;
  triggerFindTable: Ref<number>;
  billActive: Ref<string | number>;
  modalAction: Ref<ModalAction_t | null>;
  modalActionLists: ModalActionTitle[];
}

const modalActionLists: ModalActionTitle[] = [
  {
    title: "เพิ่มหมายเหตุ",
    type: "remark",
  },
  {
    title: "การยกเลิกสินค้า",
    type: "void",
  },
  {
    title: "ทำรายการย้ายโต๊ะ",
    type: "move",
  },
  {
    title: "เพิ่มส่วนลดรายสินค้า",
    type: "discount",
  },
  {
    title: "ทำรายการแยกสินค้า",
    type: "split",
  },
  {
    title: "ทำรายเพื่มค่าบริการอื่น ๆ",
    type: "additionalCost",
  },
  {
    title: "เพิ่มส่วนลดทั้งหมด",
    type: "discountOrder",
  },
  {
    title: "จัดการภาษี",
    type: "editVat",
  },
  {
    title: "จัดการค่าบริการ",
    type: "editServiceCharge",
  },
  {
    title: "ทำรายการย้ายบิล",
    type: "moveBill",
  },
  {
    title: "ทำรายการปรับราคา",
    type: "editPrice",
  },
];

const triggerFindTable: Ref<number> = ref(0);
const billActive: Ref<string | number> = ref(1);
const modalAction: Ref<ModalAction_t | null> = ref(null);
export const useTables = (): UseTable_t => {
  const getZone = () => {
    return $fetch("/pos-api/table/zone", {
      method: "GET",
      headers: getRequestHeaders(),
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };
  const getTables = (id: string, payload?: any) => {
    const params: { table_zone_id?: string } = {};
    if (id !== "all") {
      params.table_zone_id = id;
    }
    return $fetch("/pos-api/table", {
      method: "GET",
      params: {
        ...params,
        ...payload,
      },
      headers: getRequestHeaders(),
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };
  const findTable = (
    tableId: string | null,
    billNo: string | number,
    type: InformationTableFormType_t = "dineIn"
  ) => {
    const loadBillNo = billNo === "all" ? null : billNo;
    if (!tableId) {
      return Promise.resolve();
    }
    const apiPath: string =
      type === "dineIn"
        ? `/pos-api/table/${tableId}`
        : `/pos-api/takeaway/order/${tableId}`;
    return $fetch(apiPath, {
      method: "GET",
      params: {
        load_bill_no: loadBillNo,
      },
      headers: getRequestHeaders(),
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };
  const lockTables = (tableId: string | string[] | null, isLock: boolean) => {
    return $fetch(`/pos-api/table/${tableId}/set-table-lock-status`, {
      method: "POST",
      body: {
        is_locked: isLock,
      },
      headers: getRequestHeaders(),
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };

  const lockBill = (
    tableId: string | string[] | null,
    isLock: boolean,
    billNo: string | number
  ) => {
    return $fetch(`/pos-api/table/${tableId}/set-bill-lock-status`, {
      method: "POST",
      body: {
        is_locked: isLock,
        bill_no: billNo,
      },
      headers: getRequestHeaders(),
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };

  const addMenuToTable = async (
    tableId: string | string[] | null,
    payload: MenuProductPayload_t,
    type: InformationTableFormType_t = "dineIn"
  ) => {
    const pathApi =
      type === "dineIn"
        ? `/pos-api/table/${tableId}/add-menu`
        : "/pos-api/takeaway/add-menu";

    const { data, error } = await useFetch(pathApi, {
      method: "POST",
      body: payload, // No need to stringify, useFetch handles it
      headers: getRequestHeaders(),
    });

    // Handling the response and errors
    if (error.value) {
      checkAuthError((error.value as any).statusCode);
      return Promise.reject(error.value);
    }

    return new Promise((resolve) => {
      resolve(data.value);
    });
  };
  const voidItem = (
    tableId: string | string[] | null,
    payload: any,
    type: InformationTableFormType_t = "dineIn"
  ) => {
    const apiPath =
      type === "dineIn"
        ? `/pos-api/table/${tableId}/void-item`
        : `/pos-api/takeaway/order/${tableId}/void-item`;
    return $fetch(apiPath, {
      method: "POST",
      body: payload,
      headers: getRequestHeaders(),
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };
  const moveItem = (tableId: string | string[] | null, payload: any) => {
    return $fetch(`/pos-api/table/${tableId}/move-item`, {
      method: "POST",
      body: payload,
      headers: getRequestHeaders(),
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };
  const discountItem = (
    tableId: string | string[] | null,
    payload: any,
    type: InformationTableFormType_t
  ) => {
    const apiPath =
      type === "dineIn"
        ? `/pos-api/table/${tableId}/bulk-discount`
        : `/pos-api/takeaway/order/${tableId}/bulk-discount`;
    return $fetch(apiPath, {
      method: "POST",
      body: payload,
      headers: getRequestHeaders(),
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };
  const splitOrderItem = (
    tableId: string,
    payload: any,
    type: InformationTableFormType_t
  ) => {
    const apiPath =
      type === "dineIn"
        ? `/pos-api/table/${tableId}/split-order-item`
        : `/pos-api/takeaway/order/${tableId}/split-order-item`;
    return $fetch(apiPath, {
      method: "POST",
      body: payload,
      headers: getRequestHeaders(),
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };
  const splitBill = (
    tableId: string,
    payload: any,
    type: InformationTableFormType_t
  ) => {
    const apiPath =
      type === "dineIn"
        ? `/pos-api/table/${tableId}/split-bill`
        : `/pos-api/takeaway/order/${tableId}/split-bill`;
    return $fetch(apiPath, {
      method: "POST",
      body: payload,
      headers: getRequestHeaders(),
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };
  const cancelSplitBill = (
    tableId: string,
    payload: any,
    type: InformationTableFormType_t
  ) => {
    const apiPath =
      type === "dineIn"
        ? `/pos-api/table/${tableId}/cancel-split-bill`
        : `/pos-api/takeaway/order/${tableId}/cancel-split-bill`;
    return $fetch(apiPath, {
      method: "POST",
      body: payload,
      headers: getRequestHeaders(),
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };
  const addAdditionalCost = (tableId: string, payload: any) => {
    return $fetch(`/pos-api/table/${tableId}/add-additional-cost`, {
      method: "POST",
      body: payload,
      headers: getRequestHeaders(),
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };
  const removeAdditionalCost = (tableId: string | null, payload: any) => {
    return $fetch(`/pos-api/table/${tableId}/remove-additional-cost`, {
      method: "POST",
      body: payload,
      headers: getRequestHeaders(),
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };
  const getPaymentTransaction = (
    tableId: string | null,
    billNo: string | number | undefined
  ) => {
    return $fetch(`/pos-api/table/${tableId}/payment-transaction`, {
      method: "GET",
      params: {
        bill_no: billNo,
      },
      headers: getRequestHeaders(),
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };
  const addDiscountOrder = (tableId: string, payload: any) => {
    return $fetch(`/pos-api/table/${tableId}/set-order-discount`, {
      method: "POST",
      body: payload,
      headers: getRequestHeaders(),
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };
  const setVatStatus = (
    tableId: string,
    payload: any,
    type: InformationTableFormType_t
  ) => {
    const apiPath =
      type === "dineIn"
        ? `/pos-api/table/${tableId}/set-vat-status`
        : `/pos-api/takeaway/order/${tableId}/set-vat-status`;
    return $fetch(apiPath, {
      method: "POST",
      body: payload,
      headers: getRequestHeaders(),
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };
  const setServiceChargeStatus = (
    tableId: string,
    payload: any,
    type: InformationTableFormType_t
  ) => {
    const apiPath =
      type === "dineIn"
        ? `/pos-api/table/${tableId}/set-service-charge-status`
        : `/pos-api/takeaway/order/${tableId}/set-service-charge-status`;
    return $fetch(apiPath, {
      method: "POST",
      body: payload,
      headers: getRequestHeaders(),
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };
  const getMovableBill = (
    tableId: string,
    payload: any,
    type: InformationTableFormType_t
  ) => {
    const apiPath =
      type === "dineIn"
        ? `/pos-api/table/${tableId}/movable-bill`
        : `/pos-api/takeaway/order/${tableId}/movable-bill`;
    return $fetch(apiPath, {
      method: "GET",
      params: payload,
      headers: getRequestHeaders(),
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };
  const moveBill = (
    tableId: string,
    payload: any,
    type: InformationTableFormType_t
  ) => {
    const apiPath =
      type === "dineIn"
        ? `/pos-api/table/${tableId}/move-bill`
        : `/pos-api/takeaway/order/${tableId}/move-bill`;
    return $fetch(apiPath, {
      method: "POST",
      body: payload,
      headers: getRequestHeaders(),
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };

  const setItemPrice = (
    tableId: string,
    payload: any,
    type: InformationTableFormType_t
  ) => {
    const apiPath =
      type === "dineIn"
        ? `/pos-api/table/${tableId}/set-item-price`
        : `/pos-api/takeaway/order/${tableId}/set-item-price`;
    return $fetch(apiPath, {
      method: "POST",
      body: payload,
      headers: getRequestHeaders(),
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };

  const setRemark = (
    tableId: string,
    payload: any,
    type: InformationTableFormType_t
  ) => {
    const apiPath =
      type === "dineIn"
        ? `/pos-api/table/${tableId}/update-item-note`
        : `/pos-api/takeaway/order/${tableId}/update-item-note`;

    return $fetch(apiPath, {
      method: "POST",
      body: payload,
      headers: getRequestHeaders(),
    })
      .then((response) => {
        return new Promise((resolve): void => {
          resolve(response);
        });
      })
      .catch((error) => {
        checkAuthError(error.statusCode);
        return new Promise((_resolve, reject): void => {
          reject(error);
        });
      });
  };

  return {
    getZone,
    getTables,
    lockTables,
    lockBill,
    findTable,
    addMenuToTable,
    voidItem,
    moveItem,
    discountItem,
    splitOrderItem,
    splitBill,
    cancelSplitBill,
    addAdditionalCost,
    removeAdditionalCost,
    getPaymentTransaction,
    addDiscountOrder,
    setVatStatus,
    setServiceChargeStatus,
    getMovableBill,
    moveBill,
    setItemPrice,
    setRemark,
    modalActionLists,
    billActive,
    triggerFindTable,
    modalAction,
  };
};
