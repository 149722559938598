const pinAnimation: Ref<any> = ref();

interface PinScreen_t {
  createPinAnimation: () => void;
  openPinScreen: () => void;
  closePinScreen: () => void;
}
export const usePinScreen = (): PinScreen_t => {
  const elementPinScreen = "#PinScreen";
  const elementPinScreenBody = "#groupPinBody";
  const { isLocked } = useLockScroll();
  const createPinAnimation = (): void => {
    const { $gsap: gsap } = useNuxtApp();
    const pinNumberLists = gsap.utils.toArray(".pin-number-button");

    gsap.set(elementPinScreen, { autoAlpha: 0 });

    pinAnimation.value = gsap
      .timeline({ paused: true, reversed: true })
      .to(elementPinScreen, { display: "flex", duration: 0.1 })
      .to(elementPinScreen, { autoAlpha: 1, duration: 0.3, ease: "none" })
      .from(
        pinNumberLists,
        {
          scale: 0.8,
          autoAlpha: 0,
          duration: 0.2,
          stagger: {
            from: "end",
            amount: 0.2,
          },
        },
        "<"
      )
      .from(
        elementPinScreenBody,
        { autoAlpha: 0, scale: 0.9, duration: 0.3 },
        "<"
      );
  };
  const openPinScreen = (): void => {
    isLocked.value = true;
    pinAnimation.value.play();
  };
  const closePinScreen = (): void => {
    pinAnimation.value.reverse();
    isLocked.value = false;
  };

  return {
    openPinScreen,
    closePinScreen,
    createPinAnimation,
  };
};
