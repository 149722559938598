import { default as indexGCfOGkllF9Meta } from "/home/forge/pos.terragroupthailand.com/pages/branch-selector/index.vue?macro=true";
import { default as empty_45pagekkLHfeWiC2Meta } from "/home/forge/pos.terragroupthailand.com/pages/empty-page.vue?macro=true";
import { default as exampleE51RuPNS30Meta } from "/home/forge/pos.terragroupthailand.com/pages/example.vue?macro=true";
import { default as forgot_45passwordVnPxpEmxSeMeta } from "/home/forge/pos.terragroupthailand.com/pages/forgot-password.vue?macro=true";
import { default as indexIsTbYLXXkKMeta } from "/home/forge/pos.terragroupthailand.com/pages/index.vue?macro=true";
import { default as editAz0YC4XGQWMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/admin/[id]/edit.vue?macro=true";
import { default as index5dL7NIa1ZJMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/admin/[id]/index.vue?macro=true";
import { default as indexurrOyNN1wdMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/admin/index.vue?macro=true";
import { default as new0jfQcSwANJMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/admin/new.vue?macro=true";
import { default as editZdNjXmlhERMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/branch/[id]/edit.vue?macro=true";
import { default as indexIvky7ig9qKMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/branch/[id]/index.vue?macro=true";
import { default as editXTSIE6uRcZMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/branch/group/[id]/edit.vue?macro=true";
import { default as indexi6i1YKXoN4Meta } from "/home/forge/pos.terragroupthailand.com/pages/management/branch/group/[id]/index.vue?macro=true";
import { default as indexEicO9kWhR2Meta } from "/home/forge/pos.terragroupthailand.com/pages/management/branch/group/index.vue?macro=true";
import { default as newTilTOa0J9KMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/branch/group/new.vue?macro=true";
import { default as indexCqx593VxzGMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/branch/index.vue?macro=true";
import { default as editkVZ4AYvhGTMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/branch/kitchen/[id]/edit.vue?macro=true";
import { default as indexMKlVEMnFB7Meta } from "/home/forge/pos.terragroupthailand.com/pages/management/branch/kitchen/[id]/index.vue?macro=true";
import { default as index6hKK4DCnHUMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/branch/kitchen/index.vue?macro=true";
import { default as new2t5KGMa7DgMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/branch/kitchen/new.vue?macro=true";
import { default as new0FhH4nCAYmMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/branch/new.vue?macro=true";
import { default as editTYudw4seBPMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/branch/payment/[id]/edit.vue?macro=true";
import { default as indexfXooXt4BMrMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/branch/payment/[id]/index.vue?macro=true";
import { default as indexld8ZPySEa1Meta } from "/home/forge/pos.terragroupthailand.com/pages/management/branch/payment/index.vue?macro=true";
import { default as newATGMS1st89Meta } from "/home/forge/pos.terragroupthailand.com/pages/management/branch/payment/new.vue?macro=true";
import { default as editFvauPLupHMMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/branch/printer/[id]/edit.vue?macro=true";
import { default as index7faLIGzCfGMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/branch/printer/[id]/index.vue?macro=true";
import { default as editGoKFOKbh46Meta } from "/home/forge/pos.terragroupthailand.com/pages/management/branch/printer/agent/[id]/edit.vue?macro=true";
import { default as indexxHz1JA4mofMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/branch/printer/agent/[id]/index.vue?macro=true";
import { default as indexfsc5e1l56JMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/branch/printer/agent/index.vue?macro=true";
import { default as newqZmUP6XOWPMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/branch/printer/agent/new.vue?macro=true";
import { default as indexodY27cCnvZMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/branch/printer/index.vue?macro=true";
import { default as newNgh0RED0PNMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/branch/printer/new.vue?macro=true";
import { default as index5tlB185uHyMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/executive/index.vue?macro=true";
import { default as indexf9pBWh60gRMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/executive/live/branch/[bid]/index.vue?macro=true";
import { default as _91tid_93U16M6oM9i1Meta } from "/home/forge/pos.terragroupthailand.com/pages/management/executive/live/branch/[bid]/table/[tid].vue?macro=true";
import { default as indexRFEuhv31ZBMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/executive/live/branch/index.vue?macro=true";
import { default as monthlywMp6QTMtfkMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/executive/report/monthly.vue?macro=true";
import { default as _91oid_93QuQn40Dja1Meta } from "/home/forge/pos.terragroupthailand.com/pages/management/executive/report/order/[bid]/[oid].vue?macro=true";
import { default as todayRhqA1Wi6ZSMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/executive/report/today.vue?macro=true";
import { default as indexdFyGrQqqDcMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/index.vue?macro=true";
import { default as _91code_93Ft0DqPd06GMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/beverage/[code].vue?macro=true";
import { default as editr9VzQmfHhXMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/beverage/brand/[id]/edit.vue?macro=true";
import { default as indexsYezUSvrW0Meta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/beverage/brand/[id]/index.vue?macro=true";
import { default as index5myn47eU5AMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/beverage/brand/index.vue?macro=true";
import { default as newpsRYix1P2pMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/beverage/brand/new.vue?macro=true";
import { default as generateDVtGZPF3dVMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/beverage/generate.vue?macro=true";
import { default as indexxdE46wkY7FMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/beverage/index.vue?macro=true";
import { default as scan7Ytn2cQUixMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/beverage/scan.vue?macro=true";
import { default as indexfUBhJzHJ9XMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/beverage/transfer/index.vue?macro=true";
import { default as receivezcGrmzsP3pMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/beverage/transfer/receive.vue?macro=true";
import { default as sendPH337E6JKgMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/beverage/transfer/send.vue?macro=true";
import { default as _91code_931BQ4ojST49Meta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/[code].vue?macro=true";
import { default as edit1e6OSIbqcoMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/brand/[id]/edit.vue?macro=true";
import { default as indexVhyqgF5jmmMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/brand/[id]/index.vue?macro=true";
import { default as indexfXG8IuBB1RMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/brand/index.vue?macro=true";
import { default as new2fLPZU1KYOMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/brand/new.vue?macro=true";
import { default as generatekviwB4ilm8Meta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/generate.vue?macro=true";
import { default as indexTl2eVqdINiMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/index.vue?macro=true";
import { default as editwqgOePfpDSMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/place/[id]/edit.vue?macro=true";
import { default as indexEQUHJmi9vSMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/place/[id]/index.vue?macro=true";
import { default as indexl5sPbpnTmmMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/place/index.vue?macro=true";
import { default as newsMgoRk0UknMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/place/new.vue?macro=true";
import { default as scanUJGCfhZhdiMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/scan.vue?macro=true";
import { default as editkEMD2SQ84oMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/zone/[id]/edit.vue?macro=true";
import { default as indexsL95LQQZdpMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/zone/[id]/index.vue?macro=true";
import { default as indexotzcvQR4V0Meta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/zone/index.vue?macro=true";
import { default as newmg9XnYNkAAMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/zone/new.vue?macro=true";
import { default as editUKTV5bIVJ3Meta } from "/home/forge/pos.terragroupthailand.com/pages/management/material/[id]/edit.vue?macro=true";
import { default as indexYMocJLyWbfMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/material/[id]/index.vue?macro=true";
import { default as editLdxLfmR774Meta } from "/home/forge/pos.terragroupthailand.com/pages/management/material/group/[id]/edit.vue?macro=true";
import { default as index5b9MOESzT2Meta } from "/home/forge/pos.terragroupthailand.com/pages/management/material/group/[id]/index.vue?macro=true";
import { default as indexg92Z8W9frVMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/material/group/index.vue?macro=true";
import { default as newKVRLye2l50Meta } from "/home/forge/pos.terragroupthailand.com/pages/management/material/group/new.vue?macro=true";
import { default as indexZdyeKNYGDYMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/material/index.vue?macro=true";
import { default as newUuOA2laiFoMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/material/new.vue?macro=true";
import { default as indexGRw8AHz4ThMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/material/stock/index.vue?macro=true";
import { default as editYFbVVsScFwMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/[id]/edit.vue?macro=true";
import { default as indexrb8XEuGJWVMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/[id]/index.vue?macro=true";
import { default as editdlZ04MLvLrMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/group/[id]/edit.vue?macro=true";
import { default as indexhbmKyiYsrkMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/group/[id]/index.vue?macro=true";
import { default as indexcjuoE1ohIyMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/group/index.vue?macro=true";
import { default as indexZ0mrIaRloNMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/group/new/index.vue?macro=true";
import { default as _91parent_93LmciEAxWVNMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/group/new/sub/[parent].vue?macro=true";
import { default as index4jgT17euejMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/index.vue?macro=true";
import { default as newJlfEr29GwBMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/new.vue?macro=true";
import { default as edit1jbSc0vBIhMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/promotion/[id]/edit.vue?macro=true";
import { default as indexzjz8xjzS0UMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/promotion/[id]/index.vue?macro=true";
import { default as editpkSjXpp9jYMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/promotion/group/[id]/edit.vue?macro=true";
import { default as indexzueRUjjiXkMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/promotion/group/[id]/index.vue?macro=true";
import { default as indexu0JtEE8BbMMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/promotion/group/index.vue?macro=true";
import { default as newRFXddk5wssMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/promotion/group/new.vue?macro=true";
import { default as indexA4yyh5yr0NMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/promotion/index.vue?macro=true";
import { default as new3UGKSzr3eDMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/promotion/new.vue?macro=true";
import { default as editeX8MjlhCBJMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/topping/[id]/edit.vue?macro=true";
import { default as indexwqKyjIfEeUMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/topping/[id]/index.vue?macro=true";
import { default as editNlFEBunxISMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/topping/group/[id]/edit.vue?macro=true";
import { default as indexrU4X0LddZxMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/topping/group/[id]/index.vue?macro=true";
import { default as indexkd4Zacd9UfMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/topping/group/index.vue?macro=true";
import { default as newgvA0K14bUfMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/topping/group/new.vue?macro=true";
import { default as indexElnibZ2x67Meta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/topping/index.vue?macro=true";
import { default as newIByTyF36oXMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/topping/new.vue?macro=true";
import { default as editjZhnfwM9jWMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/type/[id]/edit.vue?macro=true";
import { default as indexc1zH5H2JUOMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/type/[id]/index.vue?macro=true";
import { default as indexh70dmPqozbMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/type/index.vue?macro=true";
import { default as newNFLiuh6sQoMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/menu/type/new.vue?macro=true";
import { default as indexhlJNbHfEyxMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/report/activity/index.vue?macro=true";
import { default as indexU39N2tSjzzMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/report/employee/index.vue?macro=true";
import { default as indexKZbXmtZEJEMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/report/payment/index.vue?macro=true";
import { default as indexngdv46W0hvMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/report/product/index.vue?macro=true";
import { default as _91id_932Fck5u9TFsMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/report/shift/[id].vue?macro=true";
import { default as indexyyzPNhITKNMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/report/shift/index.vue?macro=true";
import { default as indexHFGvdxNPN5Meta } from "/home/forge/pos.terragroupthailand.com/pages/management/report/stock/index.vue?macro=true";
import { default as indexFl7cbfMdt2Meta } from "/home/forge/pos.terragroupthailand.com/pages/management/report/summary/index.vue?macro=true";
import { default as editZvtN2yTLhnMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/table/[id]/edit.vue?macro=true";
import { default as index3dRYMmW6qjMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/table/[id]/index.vue?macro=true";
import { default as generateWchv8AHcP7Meta } from "/home/forge/pos.terragroupthailand.com/pages/management/table/generate.vue?macro=true";
import { default as indexKN5u6IQ8BjMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/table/index.vue?macro=true";
import { default as newEOiOS8x4lqMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/table/new.vue?macro=true";
import { default as edit6AC8MESwPiMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/table/zone/[id]/edit.vue?macro=true";
import { default as indexDH4NzIjbOWMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/table/zone/[id]/index.vue?macro=true";
import { default as indexI3Q5efosEIMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/table/zone/index.vue?macro=true";
import { default as newO8T1vEksA1Meta } from "/home/forge/pos.terragroupthailand.com/pages/management/table/zone/new.vue?macro=true";
import { default as editrWs1a47MNIMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/terminal/[id]/edit.vue?macro=true";
import { default as index2b711JNKczMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/terminal/[id]/index.vue?macro=true";
import { default as index4Rv8thyxiMMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/terminal/index.vue?macro=true";
import { default as new6nRY8MsozQMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/terminal/new.vue?macro=true";
import { default as editrWkYUZpfCbMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/user/[id]/edit.vue?macro=true";
import { default as index6pjLSpO4wpMeta } from "/home/forge/pos.terragroupthailand.com/pages/management/user/[id]/index.vue?macro=true";
import { default as indexHgMauk5DJ4Meta } from "/home/forge/pos.terragroupthailand.com/pages/management/user/index.vue?macro=true";
import { default as pin_45loginxciU3r76m1Meta } from "/home/forge/pos.terragroupthailand.com/pages/pin-login.vue?macro=true";
import { default as profileNWGlm1ZC8xMeta } from "/home/forge/pos.terragroupthailand.com/pages/profile.vue?macro=true";
import { default as _91id_93zOXEnx4Rf1Meta } from "/home/forge/pos.terragroupthailand.com/pages/sale/activity/[no]/[id].vue?macro=true";
import { default as indext0TEiuAivwMeta } from "/home/forge/pos.terragroupthailand.com/pages/sale/activity/index.vue?macro=true";
import { default as indexBnF0o3xEGBMeta } from "/home/forge/pos.terragroupthailand.com/pages/sale/index.vue?macro=true";
import { default as index5n3IkrHFxrMeta } from "/home/forge/pos.terragroupthailand.com/pages/sale/tables/index.vue?macro=true";
import { default as index1bIjDMyZpmMeta } from "/home/forge/pos.terragroupthailand.com/pages/sale/takeaway/index.vue?macro=true";
import { default as _91id_93SD23zbSdg0Meta } from "/home/forge/pos.terragroupthailand.com/pages/terminal-access/[id].vue?macro=true";
export default [
  {
    name: "branch-selector",
    path: "/branch-selector",
    meta: indexGCfOGkllF9Meta || {},
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/branch-selector/index.vue").then(m => m.default || m)
  },
  {
    name: "empty-page",
    path: "/empty-page",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/empty-page.vue").then(m => m.default || m)
  },
  {
    name: "example",
    path: "/example",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/example.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordVnPxpEmxSeMeta || {},
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexIsTbYLXXkKMeta || {},
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "management-admin-id-edit",
    path: "/management/admin/:id()/edit",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/admin/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-admin-id",
    path: "/management/admin/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/admin/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-admin",
    path: "/management/admin",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "management-admin-new",
    path: "/management/admin/new",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/admin/new.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-id-edit",
    path: "/management/branch/:id()/edit",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/branch/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-id",
    path: "/management/branch/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/branch/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-group-id-edit",
    path: "/management/branch/group/:id()/edit",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/branch/group/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-group-id",
    path: "/management/branch/group/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/branch/group/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-group",
    path: "/management/branch/group",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/branch/group/index.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-group-new",
    path: "/management/branch/group/new",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/branch/group/new.vue").then(m => m.default || m)
  },
  {
    name: "management-branch",
    path: "/management/branch",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/branch/index.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-kitchen-id-edit",
    path: "/management/branch/kitchen/:id()/edit",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/branch/kitchen/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-kitchen-id",
    path: "/management/branch/kitchen/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/branch/kitchen/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-kitchen",
    path: "/management/branch/kitchen",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/branch/kitchen/index.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-kitchen-new",
    path: "/management/branch/kitchen/new",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/branch/kitchen/new.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-new",
    path: "/management/branch/new",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/branch/new.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-payment-id-edit",
    path: "/management/branch/payment/:id()/edit",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/branch/payment/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-payment-id",
    path: "/management/branch/payment/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/branch/payment/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-payment",
    path: "/management/branch/payment",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/branch/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-payment-new",
    path: "/management/branch/payment/new",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/branch/payment/new.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-printer-id-edit",
    path: "/management/branch/printer/:id()/edit",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/branch/printer/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-printer-id",
    path: "/management/branch/printer/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/branch/printer/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-printer-agent-id-edit",
    path: "/management/branch/printer/agent/:id()/edit",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/branch/printer/agent/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-printer-agent-id",
    path: "/management/branch/printer/agent/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/branch/printer/agent/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-printer-agent",
    path: "/management/branch/printer/agent",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/branch/printer/agent/index.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-printer-agent-new",
    path: "/management/branch/printer/agent/new",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/branch/printer/agent/new.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-printer",
    path: "/management/branch/printer",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/branch/printer/index.vue").then(m => m.default || m)
  },
  {
    name: "management-branch-printer-new",
    path: "/management/branch/printer/new",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/branch/printer/new.vue").then(m => m.default || m)
  },
  {
    name: "management-executive",
    path: "/management/executive",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/executive/index.vue").then(m => m.default || m)
  },
  {
    name: "management-executive-live-branch-bid",
    path: "/management/executive/live/branch/:bid()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/executive/live/branch/[bid]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-executive-live-branch-bid-table-tid",
    path: "/management/executive/live/branch/:bid()/table/:tid()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/executive/live/branch/[bid]/table/[tid].vue").then(m => m.default || m)
  },
  {
    name: "management-executive-live-branch",
    path: "/management/executive/live/branch",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/executive/live/branch/index.vue").then(m => m.default || m)
  },
  {
    name: "management-executive-report-monthly",
    path: "/management/executive/report/monthly",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/executive/report/monthly.vue").then(m => m.default || m)
  },
  {
    name: "management-executive-report-order-bid-oid",
    path: "/management/executive/report/order/:bid()/:oid()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/executive/report/order/[bid]/[oid].vue").then(m => m.default || m)
  },
  {
    name: "management-executive-report-today",
    path: "/management/executive/report/today",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/executive/report/today.vue").then(m => m.default || m)
  },
  {
    name: "management",
    path: "/management",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-beverage-code",
    path: "/management/keep/beverage/:code()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/beverage/[code].vue").then(m => m.default || m)
  },
  {
    name: "management-keep-beverage-brand-id-edit",
    path: "/management/keep/beverage/brand/:id()/edit",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/beverage/brand/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-beverage-brand-id",
    path: "/management/keep/beverage/brand/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/beverage/brand/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-beverage-brand",
    path: "/management/keep/beverage/brand",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/beverage/brand/index.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-beverage-brand-new",
    path: "/management/keep/beverage/brand/new",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/beverage/brand/new.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-beverage-generate",
    path: "/management/keep/beverage/generate",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/beverage/generate.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-beverage",
    path: "/management/keep/beverage",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/beverage/index.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-beverage-scan",
    path: "/management/keep/beverage/scan",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/beverage/scan.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-beverage-transfer",
    path: "/management/keep/beverage/transfer",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/beverage/transfer/index.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-beverage-transfer-receive",
    path: "/management/keep/beverage/transfer/receive",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/beverage/transfer/receive.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-beverage-transfer-send",
    path: "/management/keep/beverage/transfer/send",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/beverage/transfer/send.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-code",
    path: "/management/keep/valet/:code()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/[code].vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-brand-id-edit",
    path: "/management/keep/valet/brand/:id()/edit",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/brand/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-brand-id",
    path: "/management/keep/valet/brand/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/brand/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-brand",
    path: "/management/keep/valet/brand",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/brand/index.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-brand-new",
    path: "/management/keep/valet/brand/new",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/brand/new.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-generate",
    path: "/management/keep/valet/generate",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/generate.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet",
    path: "/management/keep/valet",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/index.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-place-id-edit",
    path: "/management/keep/valet/place/:id()/edit",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/place/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-place-id",
    path: "/management/keep/valet/place/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/place/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-place",
    path: "/management/keep/valet/place",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/place/index.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-place-new",
    path: "/management/keep/valet/place/new",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/place/new.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-scan",
    path: "/management/keep/valet/scan",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/scan.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-zone-id-edit",
    path: "/management/keep/valet/zone/:id()/edit",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/zone/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-zone-id",
    path: "/management/keep/valet/zone/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/zone/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-zone",
    path: "/management/keep/valet/zone",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/zone/index.vue").then(m => m.default || m)
  },
  {
    name: "management-keep-valet-zone-new",
    path: "/management/keep/valet/zone/new",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/keep/valet/zone/new.vue").then(m => m.default || m)
  },
  {
    name: "management-material-id-edit",
    path: "/management/material/:id()/edit",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/material/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-material-id",
    path: "/management/material/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/material/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-material-group-id-edit",
    path: "/management/material/group/:id()/edit",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/material/group/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-material-group-id",
    path: "/management/material/group/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/material/group/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-material-group",
    path: "/management/material/group",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/material/group/index.vue").then(m => m.default || m)
  },
  {
    name: "management-material-group-new",
    path: "/management/material/group/new",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/material/group/new.vue").then(m => m.default || m)
  },
  {
    name: "management-material",
    path: "/management/material",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/material/index.vue").then(m => m.default || m)
  },
  {
    name: "management-material-new",
    path: "/management/material/new",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/material/new.vue").then(m => m.default || m)
  },
  {
    name: "management-material-stock",
    path: "/management/material/stock",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/material/stock/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-id-edit",
    path: "/management/menu/:id()/edit",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-id",
    path: "/management/menu/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-group-id-edit",
    path: "/management/menu/group/:id()/edit",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/group/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-group-id",
    path: "/management/menu/group/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/group/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-group",
    path: "/management/menu/group",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/group/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-group-new",
    path: "/management/menu/group/new",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/group/new/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-group-new-sub-parent",
    path: "/management/menu/group/new/sub/:parent()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/group/new/sub/[parent].vue").then(m => m.default || m)
  },
  {
    name: "management-menu",
    path: "/management/menu",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-new",
    path: "/management/menu/new",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/new.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-promotion-id-edit",
    path: "/management/menu/promotion/:id()/edit",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/promotion/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-promotion-id",
    path: "/management/menu/promotion/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/promotion/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-promotion-group-id-edit",
    path: "/management/menu/promotion/group/:id()/edit",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/promotion/group/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-promotion-group-id",
    path: "/management/menu/promotion/group/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/promotion/group/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-promotion-group",
    path: "/management/menu/promotion/group",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/promotion/group/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-promotion-group-new",
    path: "/management/menu/promotion/group/new",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/promotion/group/new.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-promotion",
    path: "/management/menu/promotion",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/promotion/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-promotion-new",
    path: "/management/menu/promotion/new",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/promotion/new.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-topping-id-edit",
    path: "/management/menu/topping/:id()/edit",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/topping/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-topping-id",
    path: "/management/menu/topping/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/topping/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-topping-group-id-edit",
    path: "/management/menu/topping/group/:id()/edit",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/topping/group/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-topping-group-id",
    path: "/management/menu/topping/group/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/topping/group/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-topping-group",
    path: "/management/menu/topping/group",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/topping/group/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-topping-group-new",
    path: "/management/menu/topping/group/new",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/topping/group/new.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-topping",
    path: "/management/menu/topping",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/topping/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-topping-new",
    path: "/management/menu/topping/new",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/topping/new.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-type-id-edit",
    path: "/management/menu/type/:id()/edit",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/type/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-type-id",
    path: "/management/menu/type/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/type/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-type",
    path: "/management/menu/type",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/type/index.vue").then(m => m.default || m)
  },
  {
    name: "management-menu-type-new",
    path: "/management/menu/type/new",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/menu/type/new.vue").then(m => m.default || m)
  },
  {
    name: "management-report-activity",
    path: "/management/report/activity",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/report/activity/index.vue").then(m => m.default || m)
  },
  {
    name: "management-report-employee",
    path: "/management/report/employee",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/report/employee/index.vue").then(m => m.default || m)
  },
  {
    name: "management-report-payment",
    path: "/management/report/payment",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/report/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "management-report-product",
    path: "/management/report/product",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/report/product/index.vue").then(m => m.default || m)
  },
  {
    name: "management-report-shift-id",
    path: "/management/report/shift/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/report/shift/[id].vue").then(m => m.default || m)
  },
  {
    name: "management-report-shift",
    path: "/management/report/shift",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/report/shift/index.vue").then(m => m.default || m)
  },
  {
    name: "management-report-stock",
    path: "/management/report/stock",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/report/stock/index.vue").then(m => m.default || m)
  },
  {
    name: "management-report-summary",
    path: "/management/report/summary",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/report/summary/index.vue").then(m => m.default || m)
  },
  {
    name: "management-table-id-edit",
    path: "/management/table/:id()/edit",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/table/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-table-id",
    path: "/management/table/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/table/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-table-generate",
    path: "/management/table/generate",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/table/generate.vue").then(m => m.default || m)
  },
  {
    name: "management-table",
    path: "/management/table",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/table/index.vue").then(m => m.default || m)
  },
  {
    name: "management-table-new",
    path: "/management/table/new",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/table/new.vue").then(m => m.default || m)
  },
  {
    name: "management-table-zone-id-edit",
    path: "/management/table/zone/:id()/edit",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/table/zone/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-table-zone-id",
    path: "/management/table/zone/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/table/zone/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-table-zone",
    path: "/management/table/zone",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/table/zone/index.vue").then(m => m.default || m)
  },
  {
    name: "management-table-zone-new",
    path: "/management/table/zone/new",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/table/zone/new.vue").then(m => m.default || m)
  },
  {
    name: "management-terminal-id-edit",
    path: "/management/terminal/:id()/edit",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/terminal/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-terminal-id",
    path: "/management/terminal/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/terminal/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-terminal",
    path: "/management/terminal",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/terminal/index.vue").then(m => m.default || m)
  },
  {
    name: "management-terminal-new",
    path: "/management/terminal/new",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/terminal/new.vue").then(m => m.default || m)
  },
  {
    name: "management-user-id-edit",
    path: "/management/user/:id()/edit",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/user/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "management-user-id",
    path: "/management/user/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/user/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "management-user",
    path: "/management/user",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/management/user/index.vue").then(m => m.default || m)
  },
  {
    name: "pin-login",
    path: "/pin-login",
    meta: pin_45loginxciU3r76m1Meta || {},
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/pin-login.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "sale-activity-no-id",
    path: "/sale/activity/:no()/:id()",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/sale/activity/[no]/[id].vue").then(m => m.default || m)
  },
  {
    name: "sale-activity",
    path: "/sale/activity",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/sale/activity/index.vue").then(m => m.default || m)
  },
  {
    name: "sale",
    path: "/sale",
    meta: indexBnF0o3xEGBMeta || {},
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/sale/index.vue").then(m => m.default || m)
  },
  {
    name: "sale-tables",
    path: "/sale/tables",
    meta: index5n3IkrHFxrMeta || {},
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/sale/tables/index.vue").then(m => m.default || m)
  },
  {
    name: "sale-takeaway",
    path: "/sale/takeaway",
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/sale/takeaway/index.vue").then(m => m.default || m)
  },
  {
    name: "terminal-access-id",
    path: "/terminal-access/:id()",
    meta: _91id_93SD23zbSdg0Meta || {},
    component: () => import("/home/forge/pos.terragroupthailand.com/pages/terminal-access/[id].vue").then(m => m.default || m)
  }
]