<template>
  <div v-once class="bg-white dark:bg-black fixed inset-0">
    <div
      class="bg-transparent text-gray-800 dark:bg-gradient-to-b dark:bg-opacity-30 dark:from-primary-500/30 dark:to-black fixed inset-0 dark:text-white flex items-center justify-center z-[10]"
    >
      <main class="relative w-[min(50rem,95vw)]">
        <section
          class="bg-white border border-gray-300 shadow-xl dark:shadow-none dark:border-transparent dark:bg-gray-800 p-4 rounded-2xl z-[100] flex flex-col md:flex-row items-center md:items-start text-center md:text-left gap-4"
        >
          <div
            class="w-fit h-fit mb-auto bg-primary-500/25 dark:bg-primary-500/10 p-4 rounded-xl text-primary-500 dark:text-primary-400"
          >
            <font-awesome-icon
              class="w-[7rem] h-[7rem]"
              :icon="['fas', 'circle-exclamation']"
            ></font-awesome-icon>
          </div>
          <section
            class="flex-1 flex flex-col gap-4 overflow-hidden w-full md:w-fit"
          >
            <header
              class="text-2xl flex md:justify-between justify-center items-center gap-2"
            >
              <span> ขออภัยเกิดข้อผิดพลาด </span>
              <span
                v-if="(error as any)?.statusCode"
                class="bg-primary-500/20 text-primary-500 p-1 px-3 rounded-md"
              >
                {{ (error as any).statusCode }}
              </span>
            </header>
            <main class="flex-1 flex flex-col gap-2">
              <div class="text-gray-500 dark:text-gray-400">
                {{ error?.message }}
              </div>
              <pre
                class="bg-gray-100 text-gray-700 dark:bg-gray-900 dark:text-gray-500 p-4 rounded-lg overflow-auto custom-scroll text-left"
                >{{ error }}
              </pre>
            </main>
            <footer class="p-2">
              <ElementsUtilsAppButton
                class="ml-auto"
                :icon="['fas', 'home']"
                icon-position="after"
                label="กลับหน้าแรก"
                @click="onClickHome"
              />
            </footer>
          </section>
        </section>
        <!-- decorator -->
        <section
          class="hidden dark:block absolute inset-[-1px] rounded-2xl bg-gradient-to-b from-primary-500 to-transparent z-[-1]"
        ></section>
        <section
          class="hidden dark:block absolute inset-[-1px] rounded-2xl bg-gradient-to-b from-primary-500 to-transparent blur-md opacity-25 z-[-1]"
        ></section>
      </main>
      <div
        class="hidden dark:block fixed top-[-40rem] md:top-[-30rem] mx-auto bg-primary-500 w-[30rem] aspect-square rounded-full blur-3xl scale-150 animate-pulse"
      />
    </div>

    <div
      v-if="(error as any)?.statusCode"
      class="fixed inset-0 flex items-center justify-center text-gray-500/5 dark:text-gray-500/20"
    >
      <span class="text-[55vw] translate-y-[10vw] mt-auto leading-none">
        {{ (error as any).statusCode }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
const error = useError();

function onClickHome() {
  navigateTo("/management");
}
</script>

<style scoped></style>
