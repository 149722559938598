import * as BPAC from "./printer/bpac";

export default defineNuxtPlugin(() => {
  const { $toast } = useNuxtApp();

  return {
    provide: {
      async print<T>(
        template: string,
        getTemplateObject: (bpacDoc: typeof BPAC.IDocument) => Promise<{
          [K in keyof T]: any;
        }>,
        values: {
          [K in keyof T]: string;
        }[]
      ) {
        try {
          // check printer and extension
          if (!BPAC.IsExtensionInstalled()) {
            $toast({
              title: "ไม่พบโปรแกรม BPAC",
              detail: "กรุณาติดตั้งโปรแกรม BPAC ก่อนใช้งาน",
              type: "error",
            });
            const agent = window.navigator.userAgent.toLowerCase();
            const ischrome =
              agent.includes("chrome") &&
              !agent.includes("edge") &&
              !agent.includes("opr");
            if (ischrome)
              window.open(
                "https://chrome.google.com/webstore/detail/ilpghlfadkjifilabejhhijpfphfcfhb",
                "_blank"
              );
            return;
          }
          // check template
          const objectDoc = BPAC.IDocument;
          const printerTemplate = await objectDoc.Open(
            `${location.protocol}//${location.host}${template}`
          );
          if (printerTemplate) {
            const templateObject = await getTemplateObject(objectDoc);
            // prints
            for (const value of values) {
              await objectDoc.StartPrint("", 0);
              for (const key in templateObject) {
                templateObject[key].Text = value[key];
              }
              await objectDoc.PrintOut(1, 0);
              await objectDoc.EndPrint();
            }
          }
          await objectDoc.Close();
        } catch (error) {
          const printErr = new Error("เกิดข้อผิดพลาดในการพิมพ์");
          printErr.message = JSON.stringify(error);
          throw printErr;
        }
      },
      async isPrinterAvailable() {
        // if extension not installed return false
        if (!BPAC.IsExtensionInstalled()) return false;

        try {
          const printerController = new BPAC.IPrinter();
          await printerController.GetInstalledPrinters();

          return true;
        } catch (error) {
          return false;
        }
      },
    },
  };
});
