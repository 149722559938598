export const useLoading = defineStore("loading", () => {
  const active = ref(false);
  const timestamp = ref({
    start: 0,
    end: 0,
  });

  function $reset() {
    active.value = false;
  }

  function loading() {
    active.value = true;
    timestamp.value.start = Date.now();
  }

  function done() {
    timestamp.value.end = Date.now();
    if (timestamp.value.end - timestamp.value.start < 1000) {
      setTimeout(() => {
        active.value = false;
      }, 1000);
    } else {
      active.value = false;
    }
  }

  return {
    active,
    $reset,
    loading,
    done,
  };
});
