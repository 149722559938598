interface ModalProduct_t {
  openModalAddProduct: () => void;
  hideModalAddProduct: () => void;
  openModalAddProductOffline: () => void;
  hideModalAddProductOffline: () => void;
  openModalActionTable: () => void;
  hideModalActionTable: () => void;
  openModalPayment: () => void;
  hideModalPayment: () => void;
  openModalActivity: () => void;
  hideModalActivity: () => void;
  openModalActivityDetail: () => void;
  hideModalActivityDetail: () => void;
  triggerCloseModalAction: Ref<number>;
  tableSignatureKey: Ref<string | undefined>;
}

const { isLocked } = useLockScroll();
const triggerCloseModalAction = ref<number>(1);
const tableSignatureKey = ref<string | undefined>(undefined);

export const useModalProduct = (): ModalProduct_t => {
  const { $gsap: gsap } = useNuxtApp();
  const modalAddProduct = "#modalAddProduct";
  const modalAction = "#modalActionTable";
  const modalPayment = "#modalPayment";
  const modalActivity = "#modalActivity";
  const modalActivityDetail = "#modalDetailActivity";
  const modalAddProductOffline = "#modalAddProductOffline";

  function openModalPayment() {
    gsap.to(modalPayment, { duration: 0.3, autoAlpha: 1 });
    isLocked.value = true;
  }

  function hideModalPayment() {
    gsap.to(modalPayment, { duration: 0.3, autoAlpha: 0 });
    isLocked.value = false;
  }

  function openModalActivity() {
    gsap.to(modalActivity, { duration: 0.3, autoAlpha: 1 });
    isLocked.value = true;
  }

  function hideModalActivity() {
    gsap.to(modalActivity, { duration: 0.3, autoAlpha: 0 });
    isLocked.value = false;
  }

  function openModalActivityDetail() {
    gsap.to(modalActivityDetail, { duration: 0.3, autoAlpha: 1 });
    isLocked.value = true;
  }

  function hideModalActivityDetail() {
    gsap.to(modalActivityDetail, { duration: 0.3, autoAlpha: 0 });
    isLocked.value = false;
  }

  function openModalActionTable() {
    gsap.to(modalAction, { duration: 0.3, autoAlpha: 1 });
    isLocked.value = true;
  }
  function hideModalActionTable() {
    gsap.to(modalAction, { duration: 0.3, autoAlpha: 0 });
    isLocked.value = false;
    triggerCloseModalAction.value++;
  }
  function openModalAddProduct() {
    gsap.to(modalAddProduct, { duration: 0.3, autoAlpha: 1 });
    isLocked.value = true;
  }

  function hideModalAddProduct() {
    gsap.to(modalAddProduct, { duration: 0.3, autoAlpha: 0 });
    isLocked.value = false;
  }

  function openModalAddProductOffline() {
    gsap.to(modalAddProductOffline, { duration: 0.3, autoAlpha: 1 });
    isLocked.value = true;
  }

  function hideModalAddProductOffline() {
    gsap.to(modalAddProductOffline, { duration: 0.3, autoAlpha: 0 });
    isLocked.value = false;
  }

  return {
    openModalAddProduct,
    hideModalAddProduct,
    openModalActionTable,
    hideModalActionTable,
    openModalPayment,
    hideModalPayment,
    openModalActivity,
    hideModalActivity,
    openModalActivityDetail,
    hideModalActivityDetail,
    openModalAddProductOffline,
    hideModalAddProductOffline,
    triggerCloseModalAction,
    tableSignatureKey,
  };
};
