interface Toast_t {
  type: "success" | "error" | "warning" | "confirm";
  title: string;
  detail: string;
  align?: "top-right" | "top-left" | "bottom-right" | "bottom-left";
  confirmText?: string;
  cancelText?: string;
  onConfirm?(): Promise<void>;
  onCancel?(): Promise<void>;
}

export const useToast = defineStore("toast-service", () => {
  const list = ref<
    {
      id: string;
      toast: Toast_t;
      timeout: number;
      timer?: NodeJS.Timeout;
    }[]
  >([]);

  function add(toast: Toast_t, timeout = 3000) {
    // set default toast alignment to top-right
    if (!toast.align) toast.align = "top-right";
    const id = Math.random().toString(36).substring(2, 9);

    const timer = setTimeout(() => {
      remove(id);
    }, timeout);

    list.value.unshift({
      id,
      toast: {
        ...toast,
      },
      timeout,
      timer,
    });
  }

  function remove(id: string) {
    list.value.splice(
      list.value.findIndex((item) => item.id === id),
      1
    );
  }

  function stopAllTimer() {
    // stop and create new timer with remaining time
    list.value.forEach((item) => {
      if (item.timer) clearTimeout(item.timer);
    });
  }

  function restartAllTimer() {
    list.value.forEach((item) => {
      if (!item.timer) return;
      item.timer = setTimeout(() => {
        remove(item.id);
      }, item.timeout);
    });
  }

  function $reset() {
    list.value = [];
  }

  return {
    $reset,
    add,
    list,
    removeById: remove,
    stopAllTimer,
    restartAllTimer,
  };
});
